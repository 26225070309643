import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FooterStyled } from "./footer.styled";
import { footerLinks, footerLinkDisplayNames } from "./footerData"; // Importamos ambos objetos
import FooterListLinks from "./FooterListLinks";
import FooterNewsLetter from "./FooterNewsLetter";
import FooterSquare from "./FooterSquare";

const Footer = () => {
  const { usefulLinks, servicesLinks } = footerLinks;

  // Traducción de los encabezados y enlaces
  const translatedUsefulLinks = {
    head: footerLinkDisplayNames.usefulLinks.head,
    links: usefulLinks.links.map(link => footerLinkDisplayNames.usefulLinks.links[link] || link),
  };

  const translatedServicesLinks = {
    head: footerLinkDisplayNames.servicesLinks.head,
    links: servicesLinks.links.map(link => footerLinkDisplayNames.servicesLinks.links[link] || link),
  };

  return (
    <FooterStyled>
      <Container>
        <hr />
        <Row className="py-3">
          <Col md={12} lg={4}>
            <FooterSquare />
          </Col>
          <Col sm={12} md={6} lg={2}>
            <FooterListLinks {...translatedUsefulLinks} />
          </Col>
          <Col sm={12} md={6} lg={2}>
            <FooterListLinks {...translatedServicesLinks} />
          </Col>
          <Col md={12} lg={4}>
            <FooterNewsLetter />
          </Col>
        </Row>
        <hr />
        <div className="d-flex align-items-center justify-content-center pb-3">
          <span style={{ fontSize: "15px" }}>
            Made by
            <a
              style={{ color: "#009688", textDecoration: "underline" }}  
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;KQTECH S.A.C. 2025. © All rights reserved.
            </a>
          </span>
        </div>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
