import {MdOutlineLocationOn, MdOutlineEmail} from "react-icons/md";
import {BiPhoneCall} from "react-icons/bi";

export const contactInfo = [
  {
    Icon: MdOutlineLocationOn,
    title: "Ubícanos en:",
    text: "Calle Leoncio Guisado Nro. 245, Urb. Rosario de Villa, Chorrillos",
  },
  {
    Icon: MdOutlineEmail,
    title: "Escríbenos",
    text: "sales@kqtech-solutions.com",
  },
  {
    Icon: BiPhoneCall,
    title: "Llámanos",
    // text: "+51 990173709",
    text: [
      "+51 990173709",
    ],
  },
];
