export const footerLinks = {
  usefulLinks: {
    head: "Useful Links",
    links: ["Home", "About", "Services", "Portfolio", "Contact"],
  },
  servicesLinks: {
    head: "Our Services",
    links: [
      "Web Design",
      "Web Development",
      "Product Management",
      "Marketing",
      "Graphic Design",
    ],
  },
};

// Objeto con las traducciones (en este caso español)
export const footerLinkDisplayNames = {
  usefulLinks: {
    head: "Enlaces Útiles",
    links: {
      Home: "Inicio",
      About: "Nosotros",
      Services: "Servicios",
      Portfolio: "Productos",
      Contact: "Contacto",
    },
  },
  servicesLinks: {
    head: "Nuestros Servicios",
    links: {
      "Web Design": "Diseño Web",
      "Web Development": "Desarrollo Web",
      "Product Management": "Gestión de Productos",
      Marketing: "Marketing",
      "Graphic Design": "Diseño Gráfico",
    },
  },
};
