import React from "react";
import {FooterSquareStyled} from "./footer.styled";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

const FooterSquare = () => {
  return (
    <FooterSquareStyled>
      <h3>KQTECH SAC</h3>
      <p className="mb-3">Calle Leoncio Guisado Nro. 245, Urb. Rosario de Villa, Chorrillos, Lima, Perú</p>
      <p className="mb-1">Phone: +51 990173709</p>
      <p className="mb-1">Email: sales@kqtech-solutions.com</p>
      {/* <ul>
        <li>
          <FaFacebookF />
        </li>
        <li>
          <FaInstagram />
        </li>
        <li>
          <FaTwitter />
        </li>
        <li>
          <FaLinkedinIn />
        </li>
      </ul> */}
    </FooterSquareStyled>
  );
};

export default FooterSquare;
