import React from "react";
import { FooterNewsLetterStyled } from "./footer.styled";

// Importa la imagen desde la carpeta assets
import whatsappLogo from "../../assets/wsp.png";

const FooterNewsLetter = () => {
  const phoneNumber = "123456789"; // Reemplaza con tu número de WhatsApp
  const message = "¡Hola! Quiero más información."; // Mensaje predefinido

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <FooterNewsLetterStyled>
      <h4>Contactanos</h4>
      <p>
        Si tiene alguna pregunta o necesita ayuda, no dude en contactarnos a través de WhatsApp.
      </p>
      <div className="subscribe-form" style={{ display: "flex", justifyContent: "center" }}>
        {/* Enlace de WhatsApp */}
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img 
            src={whatsappLogo}  // Usa la imagen importada
            alt="Contactanos por WhatsApp"
            style={{ width: "45px", height: "50px" }}  // Asignar un tamaño específico
          />
        </a>
      </div>
    </FooterNewsLetterStyled>
  );
};

export default FooterNewsLetter;
